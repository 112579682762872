import { TrackingAction, TrackingContext } from 'src/boot/plausible'
import { RouteLocationRaw } from 'vue-router'
import { ROUTE_NAME_ASSET } from 'src/router/routes'
import { useCommonStore } from 'stores/common-store'
import { AssetEntity } from 'src/entities/AssetEntity'

export class CryptoCurrencyEntity extends AssetEntity {
  protected readonly defaultIcon: string = 'spi-microchip'

  public getSubType (): string {
    return useCommonStore().getTitleById(this.id)?.type || ''
  }

  public getTrackingAction (): TrackingAction {
    return TrackingAction.OPEN_ASSET
  }

  public getTrackingContext (): TrackingContext {
    return { titleName: this.getName(), titleId: this.id, titleType: this.getSubType() }
  }

  public getRouteOptions (): RouteLocationRaw {
    return { name: ROUTE_NAME_ASSET, query: { title_id: this.id } }
  }
}
