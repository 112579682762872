import { defineStore } from 'pinia'
import {
  api
} from 'boot/axios'
import { LoginCredentials, UserData } from 'src/services/AuthenticationService'
import { Ref, ref } from 'vue'

const STORE_CURRENT_VERSION = 1

const USER_GUEST = 'guest'

const guestUser = {
  username: USER_GUEST
} as UserData

export const useAuthUserStore = defineStore('auth/user', {
  persist: {
    storage: localStorage,
    debug: true,
    afterRestore: context => {
      const restoredVersion = context.store.$state.version || 1

      if (restoredVersion < STORE_CURRENT_VERSION) {
        console.log('store was reset, restored version below current version', restoredVersion, STORE_CURRENT_VERSION)
        context.store.$reset()
      }

      if (context.store.$state.credentials) {
        // recreate class relation on hydration, so we can call `toAuthString`
        context.store.$state.credentials = new LoginCredentials(
          context.store.$state.credentials.username,
          context.store.$state.credentials.password)

        api.defaults.headers.common.Authorization = 'Basic ' + context.store.$state.credentials.toAuthString()
      }
      if (context.store.$state.loggedInDate) {
        context.store.$state.loggedInDate = new Date(context.store.$state.loggedInDate)
      }
    }
  },
  state: () => {
    const user = structuredClone(guestUser)
    const credentials : Ref<LoginCredentials|null> = ref(null)
    // TODO: logout user after certain time?
    const loggedInDate : Ref<Date|null> = ref(null)
    const version = STORE_CURRENT_VERSION
    return {
      loggedInDate,
      user,
      credentials,
      version
    }
  },
  getters: {
    fullName: (state) => `${state.user.first_name} ${state.user.last_name}`,
    initials: (state) => `${state.user.first_name[0].toUpperCase()}${state.user.last_name[0].toUpperCase()}`,
    isLoggedIn: (state) => state.user.username !== guestUser.username,
    language: (state) => state.user.language,
    getCredentials: (state) => state.credentials
  },
  actions: {
    async setUserData (userData: UserData, credentials: LoginCredentials) {
      this.credentials = credentials
      api.defaults.headers.common.Authorization = 'Basic ' + credentials.toAuthString()
      this.user = userData
      this.loggedInDate = new Date()
    },
    async logout () {
      delete api.defaults.headers.common.Authorization
      this.user = structuredClone(guestUser)
      this.loggedInDate = null
    }
  }
})
