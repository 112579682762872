import { boot } from 'quasar/wrappers'
import { createI18n } from 'vue-i18n'
import messages from 'src/i18n'
import { NumberFormatOptions, NumberFormats } from '@intlify/core-base'

export const NUMBER_FORMAT_COMPACT_DECIMAL = 'compactDecimal'
export const NUMBER_FORMAT_DECIMAL_03 = 'decimal03'
export const NUMBER_FORMAT_DECIMAL_11 = 'decimal11'
export const NUMBER_FORMAT_DECIMAL_22 = 'decimal22'
export const NUMBER_FORMAT_DECIMAL_22_WITH_SIGN = 'decimal22WithSign'
export const NUMBER_FORMAT_DECIMAL_33 = 'decimal33'
export const NUMBER_FORMAT_PERCENT_22 = 'percent22'
export const NUMBER_FORMAT_PERCENT_22_WITH_SIGN = 'percent22WithSign'
export const NUMBER_FORMAT_PERCENT_11 = 'percent11'
export const NUMBER_FORMAT_CURRENCY = 'currency'
export const NUMBER_FORMAT_CURRENCY_WITH_SIGN = 'currencyWithSign'

const numberFormats: NumberFormats = {
  'en-US': {
    [NUMBER_FORMAT_COMPACT_DECIMAL]: {
      style: 'decimal', notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 1
    },
    [NUMBER_FORMAT_DECIMAL_03]: {
      style: 'decimal', notation: 'standard', minimumFractionDigits: 0, maximumFractionDigits: 3
    },
    [NUMBER_FORMAT_DECIMAL_11]: {
      style: 'decimal', notation: 'standard', minimumFractionDigits: 1, maximumFractionDigits: 1
    },
    [NUMBER_FORMAT_DECIMAL_22]: {
      style: 'decimal', notation: 'standard', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    [NUMBER_FORMAT_DECIMAL_22_WITH_SIGN]: {
      style: 'decimal', notation: 'standard', minimumFractionDigits: 2, maximumFractionDigits: 2, signDisplay: 'exceptZero'
    },
    [NUMBER_FORMAT_DECIMAL_33]: {
      style: 'decimal', notation: 'standard', minimumFractionDigits: 3, maximumFractionDigits: 3
    },
    [NUMBER_FORMAT_PERCENT_22]: {
      style: 'percent', notation: 'standard', useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    [NUMBER_FORMAT_PERCENT_22_WITH_SIGN]: {
      style: 'percent', notation: 'standard', useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2, signDisplay: 'exceptZero'
    },
    [NUMBER_FORMAT_PERCENT_11]: {
      style: 'percent', notation: 'standard', useGrouping: false, minimumFractionDigits: 1, maximumFractionDigits: 1
    },
    [NUMBER_FORMAT_CURRENCY]: {
      style: 'currency', currency: 'USD', notation: 'standard', minimumFractionDigits: 2, maximumFractionDigits: 2
    },
    [NUMBER_FORMAT_CURRENCY_WITH_SIGN]: {
      style: 'decimal', notation: 'standard', minimumFractionDigits: 2, maximumFractionDigits: 2, signDisplay: 'exceptZero'
    }
  }
}

export type MessageLanguages = keyof typeof messages;
// Type-define 'en-US' as the master schema for the resource
export type MessageSchema = typeof messages['en-US'];

// See https://vue-i18n.intlify.dev/guide/advanced/typescript.html#global-resource-schema-type-definition
/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'vue-i18n' {
  // define the locale messages schema
  export interface DefineLocaleMessage extends MessageSchema {}

  // define the datetime format schema
  export interface DefineDateTimeFormat {}

  // define the number format schema
  export interface DefineNumberFormat {}
}
/* eslint-enable @typescript-eslint/no-empty-interface */

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $formats: {
      currency: (value: number, currency: string, useCompact?: boolean, fractionDigits?: number, withSign?: boolean) => string
    }
  }
}
export const i18n = createI18n({
  locale: 'en-US',
  legacy: false,
  globalInjection: true,
  // TODO: missing translation warnings disabled in early dev
  missingWarn: false,
  // TODO: missing translation warnings disabled in early dev
  fallbackWarn: false,
  messages,
  numberFormats
})

const formats = {
  // TODO_NEXT refactor. No Fallback and handle 'PTS' differently
  currency (value: number, currency: string, useCompact = false, fractionDigits = 2, withSign = false): string {
    let baseNumberFormat = NUMBER_FORMAT_DECIMAL_22
    const locale = i18n.global.locale.value

    if (!currency || currency.length !== 3) {
      currency = 'USD'
    }

    const args: NumberFormatOptions = {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    }

    if (useCompact) {
      args.notation = 'compact'
      args.minimumFractionDigits = 0
      args.maximumFractionDigits = 1
    }

    if (withSign) {
      args.signDisplay = 'exceptZero'
    }

    if (currency !== 'PTS') {
      args.currency = currency
      baseNumberFormat = NUMBER_FORMAT_CURRENCY
    }

    const keyOfNumberFormats = currency + useCompact + fractionDigits + withSign

    if (!numberFormats[i18n.global.locale.value][keyOfNumberFormats]) {
      const baseNumberFormatOptions = numberFormats[i18n.global.locale.value][baseNumberFormat]
      numberFormats[i18n.global.locale.value][keyOfNumberFormats] = { ...baseNumberFormatOptions, ...args } as NumberFormatOptions
      i18n.global.mergeNumberFormat(locale, numberFormats[i18n.global.locale.value])
    }

    return i18n.global.n(value, keyOfNumberFormats)
  }
}

export type FormatsType = typeof formats

export default boot(({ app }) => {
  // Set i18n instance on app
  app.use(i18n)

  app.config.globalProperties.$formats = {
    // ^ ^ ^ this will allow you to use this.$formats (for Vue Options API form) and $formats in templates
    currency (value: number, currency: string, useCompact = false, fractionDigits = 2, withSign = false) {
      let baseNumberFormat = NUMBER_FORMAT_DECIMAL_22
      const locale = i18n.global.locale.value

      if (!currency || currency.length !== 3) {
        currency = 'USD'
      }

      const args: NumberFormatOptions = {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
      }

      if (useCompact) {
        args.notation = 'compact'
        args.minimumFractionDigits = 0
        args.maximumFractionDigits = 1
      }

      if (withSign) {
        args.signDisplay = 'exceptZero'
      }

      if (currency !== 'PTS') {
        args.currency = currency
        baseNumberFormat = NUMBER_FORMAT_CURRENCY
      }

      const keyOfNumberFormats = currency + useCompact + fractionDigits + withSign

      if (!numberFormats[i18n.global.locale.value][keyOfNumberFormats]) {
        const baseNumberFormatOptions = numberFormats[i18n.global.locale.value][baseNumberFormat]
        numberFormats[i18n.global.locale.value][keyOfNumberFormats] = { ...baseNumberFormatOptions, ...args } as NumberFormatOptions
        i18n.global.mergeNumberFormat(locale, numberFormats[i18n.global.locale.value])
      }

      return i18n.global.n(value, keyOfNumberFormats)
    }
  }
})

export { formats }
