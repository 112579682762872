import { AxiosRequestConfig, AxiosResponse, CanceledError } from 'axios'
import { api } from 'boot/axios'

declare module 'axios' {
  interface AxiosRequestConfig {
    attemptNumber?: number;
    abortController?: AbortController;
    priority?: number,
    resolve?: (value: AxiosRequestConfig | PromiseLike<AxiosRequestConfig>) => void,
  }
}

const MIN_RETRY_DELAY_MS = 1000
const MAX_RETRY_DELAY_MS = 30000

// TODO: specify status codes
const DO_NOT_RETRY_STATUS = [404, 403, 401]

const DO_NOT_CANCEL_ROUTES = ['/watchlists/']

export interface RouteThrottleConfig {
  route: string;
  concurrency: number;
}

export class AxiosRequestManager {
  private routeThrottleConfigs: RouteThrottleConfig[]
  private activeRequests: Map<string, AxiosRequestConfig[]>
  private requestQueues: Map<string, AxiosRequestConfig[]>

  constructor (routeThrottleConfigs: RouteThrottleConfig[]) {
    this.routeThrottleConfigs = routeThrottleConfigs
    this.activeRequests = new Map<string, AxiosRequestConfig[]>()
    this.requestQueues = new Map<string, AxiosRequestConfig[]>()
  }

  private getBackoffDelay (attempt: number): number {
    let delay = Math.pow(2, attempt) * MIN_RETRY_DELAY_MS
    delay = Math.min(delay, MAX_RETRY_DELAY_MS)
    // add 1% to 10% jitter
    delay = delay + this.randomInteger(delay / 100, delay / 10)
    return delay
  }

  private randomInteger (min: number, max: number): number {
    return (Math.floor(Math.random() * (max - min + 1)) + min) | 0
  }

  private getPriority (url: string) {
    // TODO_NEXT: find a better place and way to prioritize search box requests
    return url.includes('search?') ? 100 : 50
  }

  private enqueueRequest (config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    return new Promise((resolve) => {
      const routeConfig = this.routeThrottleConfigs.find(c => config.url?.includes(c.route))
      if (!routeConfig) {
        return Promise.resolve(config)
      }

      if (config.abortController) {
        console.warn('TODO already existing abort controller!')
      }

      config.abortController = new AbortController()
      config.signal = config.abortController.signal
      config.priority = this.getPriority(config.url ?? '')
      config.resolve = resolve
      config.attemptNumber ??= 0

      const queue = this.requestQueues.get(routeConfig.route) || []

      // enqueue by priority
      const index = queue.findIndex((elem: AxiosRequestConfig) => (config.priority ?? 0) > (elem.priority ?? 0))
      if (index !== -1) {
        console.log('request was inserted with priority into queue', index, config)
        queue.splice(index, 0, config)
      } else {
        queue.push(config)
      }

      this.requestQueues.set(routeConfig.route, queue)
      this.processQueue(routeConfig.route)
    })
  }

  private processQueue (route: string): void {
    const queue = this.requestQueues.get(route)
    if (!queue || queue.length === 0) {
      return
    }

    const routeActiveRequests = this.activeRequests.get(route) || []
    const routeConfig = this.routeThrottleConfigs.find(c => c.route === route)

    if (routeConfig && routeActiveRequests.length < routeConfig.concurrency) {
      let item : AxiosRequestConfig | undefined
      do {
        item = queue.shift()
      } while ((item && item.signal?.aborted))
      if (item && item.resolve) {
        routeActiveRequests.push(item)
        this.activeRequests.set(route, routeActiveRequests)
        item.resolve(item)
      }
    }
  }

  // TODO_NEXT: this should return Promise<AxiosRequestConfig> instead of any, but types seem to be broken:
  // https://github.com/axios/axios/issues/5494 maybe upgrading fixes it
  public interceptRequest (config: AxiosRequestConfig): Promise<any> {
    return this.enqueueRequest(config)
  }

  public interceptResponse (response: AxiosResponse): AxiosResponse {
    const routeConfig = this.routeThrottleConfigs.find(c => response.config.url?.includes(c.route))
    if (routeConfig && response.config) {
      const activeRequests = (this.activeRequests.get(routeConfig.route) || [])
      const index = activeRequests.findIndex(obj => obj === response.config)
      if (index !== -1) {
        activeRequests.splice(index, 1)
      }
      this.activeRequests.set(routeConfig.route, activeRequests)
      this.processQueue(routeConfig.route)
    }
    return response
  }

  public interceptResponseError (error: any): any {
    if (error.config && error.config.url) {
      const config = error.config
      const routeConfig = this.routeThrottleConfigs.find(c => error.config.url.includes(c.route))
      if (routeConfig) {
        const activeRequests = (this.activeRequests.get(routeConfig.route) || [])
        const index = activeRequests.findIndex(obj => obj === config)
        if (index !== -1) {
          activeRequests.splice(index, 1)
        }
        this.activeRequests.set(routeConfig.route, activeRequests)
        this.processQueue(routeConfig.route)
      }

      const wasAborted = error.config.signal?.aborted || error instanceof CanceledError
      const retryOnThisStatusCode = !DO_NOT_RETRY_STATUS.includes(error.response?.status)

      if (!wasAborted && retryOnThisStatusCode) {
        const delay = this.getBackoffDelay(config.attemptNumber)
        console.log('request failed and will be re-queued after', delay, config)
        return new Promise((resolve) => {
          window.setTimeout(() => {
            config.abortController = undefined
            config.attemptNumber = config.attemptNumber + 1
            // TODO_NEXT: make dependency of axios instance explicit
            resolve(api(config))
          }, delay)
        })
      }

      if (wasAborted) {
        return Promise.resolve()
      }
    }

    return Promise.reject(error)
  }

  public cancelRequests (doNotCancelFilter?: (request: AxiosRequestConfig) => boolean): void {
    for (const queuedRequests of this.requestQueues.values()) {
      if (queuedRequests) {
        for (const request of queuedRequests) {
          if (DO_NOT_CANCEL_ROUTES.filter((x) => request.url?.includes(x)).length > 0) {
            continue
          }
          if (doNotCancelFilter && doNotCancelFilter(request)) {
            continue
          }
          request.abortController?.abort()
        }
      }
    }
    this.requestQueues.clear()

    for (const activeRequests of this.activeRequests.values()) {
      if (activeRequests) {
        for (const request of activeRequests) {
          if (DO_NOT_CANCEL_ROUTES.filter((x) => request.url?.includes(x)).length > 0) {
            continue
          }
          if (doNotCancelFilter && doNotCancelFilter(request)) {
            continue
          }
          request.abortController?.abort()
        }
      }
    }
    this.activeRequests.clear()
  }
}
