import { LocationQueryValue, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { EntityType } from '@stockpulse/typescript-axios'

export const ROUTE_NAME_HOME = 'home'
export const ROUTE_NAME_SEARCH = 'search'

export const ROUTE_NAME_TEST = 'test'

export const ROUTE_NAME_LOGIN = 'login'

export const ROUTE_NAME_ASSET = 'asset'
export const ROUTE_NAME_VIEW = 'view'
export const ROUTE_NAME_AUTHOR = 'author'
export const ROUTE_NAME_KEYEVENT = 'keyEvent'

export const ROUTE_NAME_INTEGRATED_HOME = 'ihome'

export const ROUTE_NAME_EXPLORE = 'explore'

export const ROUTE_NAME_SETTINGS = 'settings'

export const ROUTE_NAME_ERROR = 'error'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_HOME,
        path: '',
        component: () => import('pages/IndexPage.vue')
      }
    ],
    props: {
      displaySearchBar: false
    }
  },

  {
    path: '/simple-ui-integrated/',
    component: () => import('layouts/IntegratedLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_INTEGRATED_HOME,
        path: '',
        component: () => import('pages/IndexPage.vue')
      }
    ],
    props: {
      displaySearchBar: false
    }
  },

  {
    path: '/search',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_SEARCH,
        path: '',
        component: () => import('pages/SearchPage.vue'),
        props: (route: RouteLocationNormalized): {q: LocationQueryValue | LocationQueryValue[]} => {
          return { q: route.query.q }
        }
      }
    ]
  },
  {
    path: '/explore',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_EXPLORE,
        path: '',
        component: () => import('pages/ExplorePage.vue'),
        props: parseRouteExploreProps
      }
    ],
    props: {
      displayNavigation: true
    }
  },
  {
    path: '/asset',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_ASSET,
        path: '',
        component: () => import('pages/AssetSnapshot.vue'),
        props: parseRouteAssetViewProps
      }
    ],
    props: {
      displaySearchResult: true
    }
  },
  {
    path: '/keyevent',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_KEYEVENT,
        path: '',
        component: () => import('pages/KeyEventPage.vue'),
        props: parseRouteKeyEventPageProps
      }
    ],
    props: {
      displaySearchResult: true,
      displayFollow: false
    }
  },
  {
    path: '/view',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_VIEW,
        path: '',
        component: () => import('pages/ListView.vue'),
        props: parseRouteListViewProps
      }
    ],
    props: {
      displaySearchResult: true
    }
  },
  {
    path: '/author',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_AUTHOR,
        path: '',
        component: () => import('pages/AuthorView.vue'),
        props: parseRouteAuthorViewProps
      }
    ],
    props: {
      displaySearchResult: true,
      displaySourceFilter: false
    }
  },

  {
    path: '/test',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_TEST,
        path: '',
        component: () => import('pages/TestPage.vue'),
        props: (route: RouteLocationNormalized): {q: LocationQueryValue | LocationQueryValue[]} => {
          return { q: route.query.q }
        }
      }
    ]
  },
  {
    path: '/settings',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_SETTINGS,
        path: '',
        component: () => import('pages/SettingsPage.vue')
      }
    ],
    props: {
      displayNavigation: true
    }
  },

  {
    path: '/login',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        name: ROUTE_NAME_LOGIN,
        path: '',
        component: () => import('pages/LoginPage.vue'),
        props: parseRouteLoginProps
      }
    ]
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

// TODO: discuss: currently properties are defined optionally even if they are required,
//  we may redirect the to user to somewhere in this case or the like
// maybe do something like RouteProps<T> { error: bool, element: T } and use layout to display error instead of page

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RouteExploreProps {}

export function parseRouteExploreProps (route: RouteLocationNormalized) : RouteExploreProps {
  return {}
}
export interface RouteLoginProps { redirect?: string, resetToken?: string }

export function parseRouteLoginProps (route: RouteLocationNormalized) : RouteLoginProps {
  const obj: RouteLoginProps = {}
  if (typeof route.query?.redirect === 'string') {
    obj.redirect = route.query.redirect
  }
  if (typeof route.query?.reset_token === 'string') {
    obj.resetToken = route.query.reset_token
  }
  return obj
}

export interface RouteAssetViewProps { title_id?: number }

export function parseRouteAssetViewProps (route: RouteLocationNormalized) : RouteAssetViewProps {
  const num = Number(route.query?.title_id)
  return Number.isSafeInteger(num) ? { title_id: num } : {}
}

export interface RouteListViewProps {
  list: number[],
  sector: number[],
  exchange: number[],
  region: string[],
  country: string[],
  keyEvent: number[],
  asset: number[],
  type: EntityType[],
  searchQuery: string
}

export function parseRouteListViewProps (route: RouteLocationNormalized) : RouteListViewProps {
  const props : RouteListViewProps = { list: [], sector: [], exchange: [], region: [], country: [], keyEvent: [], asset: [], type: [], searchQuery: '' }
  props.list.push(...filterNumberOrNumberArray(route.query?.list))
  props.sector.push(...filterNumberOrNumberArray(route.query?.sector))
  props.exchange.push(...filterNumberOrNumberArray(route.query?.exchange))
  props.region.push(...filterStringOrStringArray(route.query?.region))
  props.country.push(...filterStringOrStringArray(route.query?.country))
  props.keyEvent.push(...filterNumberOrNumberArray(route.query?.keyEvent))
  props.asset.push(...filterNumberOrNumberArray(route.query?.asset))
  props.type.push(...filterStringOrStringArray(route.query?.type) as EntityType[])
  props.searchQuery = route.query?.searchQuery ? route.query?.searchQuery as string : ''
  return props
}

export interface RouteKeyEventPageProps {
  keyEvent?: number
}

export function parseRouteKeyEventPageProps (route: RouteLocationNormalized) : RouteKeyEventPageProps {
  const num = Number(route.query?.keyEvent)
  return Number.isSafeInteger(num) ? { keyEvent: num } : {}
}

function filterNumberOrNumberArray (input: unknown): number[] {
  if (!input || (Array.isArray(input) && input.length === 0)) {
    return []
  }
  const singleElement = Number(input)
  if (Number.isSafeInteger(singleElement)) {
    return [singleElement]
  }
  if (Array.isArray(input)) {
    return input.reduce((acc: number[], element: unknown) => {
      const num = Number(element)
      if (Number.isSafeInteger(num)) {
        acc.push(num)
      }
      return acc
    }, [])
  }
  return []
}

function filterStringOrStringArray (input: unknown): string[] {
  if (!input) {
    return []
  }
  if (typeof input === 'string') {
    return [input]
  }
  if (Array.isArray(input)) {
    return input.reduce((acc: string[], element: unknown) => {
      if (typeof element === 'string') {
        acc.push(element)
      }
      return acc
    }, [])
  }
  return []
}

export interface RouteAuthorViewProps { name?: string, source?: string }

export function parseRouteAuthorViewProps (route: RouteLocationNormalized) : RouteAuthorViewProps {
  const name = typeof route.query?.name === 'string' ? route.query.name : undefined
  const source = typeof route.query?.source === 'string' ? route.query.source : undefined
  return name && source ? { name, source } : {}
}

export default routes
