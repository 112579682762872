import { defineStore } from 'pinia'
import { differenceInMilliseconds, Duration, endOfDay, startOfDay, sub } from 'date-fns'
import { Ref, ref } from 'vue'
import { useQuasar } from 'quasar'

export interface TimeSpan {
  name: string,
  duration: Duration
    disabled?: boolean
}

const timeSpanOptions: TimeSpan[] = [
  {
    name: 'Last 1 hour',
    duration: { hours: 1 }
  },
  {
    name: 'Last 3 hours',
    duration: { hours: 3 }
  },
  {
    name: 'Last 24 hours',
    duration: { hours: 24 }
  },
  {
    name: 'Last 3 days',
    duration: { days: 3 }
  },
  {
    name: 'Last 7 days',
    duration: { days: 7 }
  },
  {
    name: 'Last 30 days',
    duration: { days: 30 }
  },
  {
    name: 'Last 90 days',
    duration: { days: 90 },
    disabled: true
  },
  {
    name: 'Last 6 months',
    duration: { months: 6 },
    disabled: true
  },
  {
    name: 'Last 1 year',
    duration: { years: 1 },
    disabled: true
  }
]

interface SelectedTimeRange {
  startDate: Date | undefined,
  endDate: Date,
  selectedTimeSpan: TimeSpan | undefined
}

/**
 * TimeRageStore to globally set selected Time-Range
 */
export const timeRangeStore = defineStore('timeRange', () => {
  const $q = useQuasar()
  const selectedTimeRange : Ref<SelectedTimeRange> = ref({
    startDate: undefined,
    endDate: (new Date()),
    selectedTimeSpan: timeSpanOptions[3]
  })
  function getCurrentStartDate () : Date {
    const selectedTimeSpan = selectedTimeRange.value.selectedTimeSpan
    if (selectedTimeSpan) {
      let startDate = sub(new Date(), selectedTimeSpan.duration)
      if (selectedTimeSpan.duration.days || selectedTimeSpan.duration.months || selectedTimeSpan.duration.years) {
        startDate = startOfDay(sub(selectedTimeRange.value.endDate, selectedTimeSpan.duration))
      }
      return startDate
    }
    // JLDEBUG DEFAULT??
    return selectedTimeRange.value.startDate ? selectedTimeRange.value.startDate : new Date()
  }

  function getCurrentEndDate () {
    return selectedTimeRange.value.endDate
  }

  function getCurrentExtendedStartDate () : Date {
    const selectedTimeSpan = selectedTimeRange.value.selectedTimeSpan

    // For a dynamic time period choose the next one as extended start date
    if (selectedTimeSpan) {
      const selectedTimeSpanIndex = timeSpanOptions.findIndex(timeSpan => timeSpan.name === selectedTimeSpan.name)
      const extendedTimeSpan = timeSpanOptions.at(selectedTimeSpanIndex + 1) || selectedTimeSpan
      let startDate = sub(new Date(), extendedTimeSpan.duration)
      if (extendedTimeSpan.duration.days || extendedTimeSpan.duration.months || extendedTimeSpan.duration.years) {
        startDate = startOfDay(sub(selectedTimeRange.value.endDate, extendedTimeSpan.duration))
      }
      return startDate
    }

    // For a fixed time period use the start date with doubled duration
    if (selectedTimeRange.value.endDate && selectedTimeRange.value.startDate) {
      const startTs = selectedTimeRange.value.startDate.getTime()
      const endTs = selectedTimeRange.value.endDate.getTime()
      // Calculated extended start date by subtracting duration of selected time period from startDate
      return new Date(startTs - (endTs - startTs))
    }

    return new Date()
  }

  function setSelectedTimeSpan (timeSpan: TimeSpan) {
    let now = endOfDay(new Date())
    let startDate = sub(now, timeSpan.duration)
    if (timeSpan.duration.days || timeSpan.duration.months || timeSpan.duration.years) {
      startDate = startOfDay(sub(now, timeSpan.duration))
    }

    if (timeSpan.duration.hours) {
      now = new Date()
      startDate = sub(now, timeSpan.duration)
    }

    selectedTimeRange.value = {
      startDate,
      endDate: now,
      selectedTimeSpan: timeSpan
    }
  }
  function setTimeRange (startDate: Date, endDate: Date) {
    selectedTimeRange.value = {
      startDate,
      endDate,
      selectedTimeSpan: undefined
    }
  }
  function getApiTimestamp (date : Date) {
    return (date.getTime() / 1000).toFixed(3)
  }
  function getPeriod (): string {
    return ''
    /* const formatter = new Intl.DateTimeFormat($q.lang.isoName, { dateStyle: 'short', timeStyle: 'short' })

      const start = formatter.format(this.getCurrentStartDate)
      const to = '' // JLTODO
      const end = formatter.format(this.getCurrentEndDate)

      return `${start} ${to} ${end}` */
  }

  function isIntradaySelection () {
    if (selectedTimeRange.value.selectedTimeSpan?.duration?.hours !== undefined) {
      return true
    }
    return differenceInMilliseconds(getCurrentEndDate(), getCurrentStartDate()) < 24 * 60 * 60 * 1000
  }

  function getTimeSpanOptionIndexByName (name: string) :number {
    return timeSpanOptions.findIndex(timeSpan => timeSpan.name === name)
  }

  function getSelectionLabel () {
    const currentSelection = selectedTimeRange.value
    if (currentSelection.selectedTimeSpan) {
      return currentSelection.selectedTimeSpan.name
    }
    if (!currentSelection.startDate) {
      return '-'
    }
    return `${formatDate(currentSelection.startDate.getTime())} to ${formatDate(currentSelection.endDate.getTime())}`
  }

  function formatDate (timestamp: number): string {
    const date = new Date(timestamp)
    return date.toLocaleString($q.lang.isoName, { hour12: false })
  }

  return {
    selectedTimeRange,
    getSelectionLabel,
    getPeriod,
    getApiTimestamp,
    setTimeRange,
    setSelectedTimeSpan,
    getCurrentEndDate,
    getCurrentStartDate,
    getCurrentExtendedStartDate,
    isIntradaySelection,
    getTimeSpanOptionIndexByName,
    timeSpanOptions
  }
})
