import { TrackingAction, TrackingContext } from 'src/boot/plausible'
import { AbstractEntity } from 'src/entities/AbstractEntity'
import { RouteLocationRaw } from 'vue-router'
import { ROUTE_NAME_VIEW } from 'src/router/routes'
import { useCommonStore } from 'stores/common-store'

export class ExchangeEntity extends AbstractEntity {
  protected readonly defaultIcon: string = 'spi-landmark'

  public getNameFromInventory (): string {
    return useCommonStore().getExchangeById(this.id)?.name || ''
  }

  public getShortName (): string {
    return useCommonStore().getExchangeById(this.id)?.mic || ''
  }

  public getIcon (): string {
    return useCommonStore().getExchangeById(this.id)?.icon || this.defaultIcon
  }

  public getSubType (): string {
    return ''
  }

  public getTrackingAction (): TrackingAction {
    return TrackingAction.OPEN_VIEW
  }

  public getTrackingContext (): TrackingContext {
    return { exchangeName: this.getName(), exchangeId: this.id }
  }

  public getRouteOptions (): RouteLocationRaw {
    return { name: ROUTE_NAME_VIEW, query: { exchange: [this.id] } }
  }
}
