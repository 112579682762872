import { capitalize, Ref, ref, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { WebSocketAuthStatus, webSocketStore } from 'stores/web-socket-store'
import { api, fetchInfosForTitles, FilterSettingsList, filterTitles, ViewFilter } from 'boot/axios'

import { AsyncData } from 'components/models'
import { useAuthUserStore } from 'stores/auth-user-store'
import { CountriesInner, ExchangesInner, ListsInner, TitleInformation } from '@stockpulse/typescript-axios'
import { ROUTE_NAME_ASSET, ROUTE_NAME_VIEW } from 'src/router/routes'
import { useRouter } from 'vue-router'
import NotificationService from 'src/services/NotificationService'
import { useI18n } from 'vue-i18n'
import { filterV3FieldNames } from 'stores/watchlist-store'
import { TitleInventoryInner } from '@stockpulse/typescript-axios/types/title-inventory-inner'
import { SubscriptionId, useRealTimeDataStore } from 'stores/realtime-data-store'
import { Auxiliary } from 'src/helper/Auxiliary'

export interface Signal {
  b: number,
  id: number,
  r_type: string,
  s: number,
  s_id: number,
  s_n: string,
  t: number,
  t_id: number,
  title_name: string
}

export interface InventoryTitle {
  n: string,
  type: string,
  id: number,
  icon: string
}

export interface StreamEvent {
  titleId: number,
  time: number,
  label: string,
  buzz: number,
  sentimentScore: number,
  sentimentIcon: string,
  sentimentIconColor: string,
  className: string,
  icon?: string
}

type ExtendedCountriesInner = CountriesInner & {icon?: string}

export interface InventorySector {name: string, type: string, id: number, icon: string}

export type ExchangeData = ExchangesInner & { icon?: string }

export interface KeyEvent {id:number, name:string, icon:string}

export interface Source {source:string, lang:string, type:string, source_type:string, icon?:string}

export interface TrendingKeyEvents {[key:number]:number}

const SIGNAL_GROUP_IDENTIFIER = 'signal'

export const useCommonStore = defineStore('commonStore', () => {
  // "You must call useI18n at top of the setup"
  const { t } = useI18n()
  const router = useRouter()
  const notificationService = new NotificationService(router)
  const webSocket = webSocketStore()
  const authUserStore = useAuthUserStore()
  const realtimeDataStore = useRealTimeDataStore()
  const { authStatus } = storeToRefs(webSocket)
  const { isLoggedIn } = storeToRefs(authUserStore)

  const isInitialized = ref(false)
  const keyEvents: Ref<AsyncData<{[key: number]: KeyEvent}>> = ref({ loading: true, value: {} })
  // const titles: Ref<AsyncData<TitleInformation[]>> = ref({ loading: true, value: [] })
  const titles: Ref<AsyncData<{[key: number]: InventoryTitle}>> = ref({ loading: true, value: {} })
  const sectors: Ref<AsyncData<{[key: number]:InventorySector}>> = ref({ loading: true, value: {} })
  const countries: Ref<AsyncData<ExtendedCountriesInner[]>> = ref({ loading: true, value: [] })
  const exchanges: Ref<AsyncData<ExchangeData[]>> = ref({ loading: true, value: [] })
  const lists: Ref<AsyncData<ListsInner[]>> = ref({ loading: true, value: [] })
  const sources: Ref<AsyncData<Source[]>> = ref({ loading: true, value: [] })
  const preIPOCompanies: Ref<AsyncData<TitleInformation[]>> = ref({ loading: true, value: [] })
  const topBuzzTitleIds: Ref<AsyncData<number[]>> = ref({ loading: true, value: [] })
  const topBuzzIndexIds: Ref<AsyncData<number[]>> = ref({ loading: true, value: [] })
  const eventStream: Ref<StreamEvent[]> = ref([])
  const trendingKeyEvents: Ref<AsyncData<TrendingKeyEvents>> = ref({ loading: true, value: {} })

  const subscriptionIds: Ref<SubscriptionId[]> = ref([])

  const onIsLoggedIn = (newValue: boolean) => {
    if (newValue && !isInitialized.value) {
      loadData()
    }
  }

  // trigger login check immediate, to open socket after auth store hydration
  watch(isLoggedIn, onIsLoggedIn, { immediate: true })

  function emitToWebsocket () {
    // Subscribe to StdSignals
    webSocket.emitSubscribeStdSignals(handleStdSignalMessage)
    webSocket.emitSubscribeSignals(handleSignalMessage)
  }

  const onWebsocketOpen = (newValue: string) => {
    if (newValue === WebSocketAuthStatus.AUTHENTICATED) {
      emitToWebsocket()
    }
  }
  watch(authStatus, onWebsocketOpen)
  onWebsocketOpen(authStatus.value)

  function handleStdSignalMessage (data: unknown, error: unknown) {
    if (error) {
      return
    }
    const signalData = data as Signal

    // Check for duplicate
    if (eventStream.value.find(ev => ev.time === signalData.t && ev.titleId === signalData.id)) {
      return
    }
    notificationService.notify({
      message: signalData.s_n,
      caption: signalData.title_name,
      group: SIGNAL_GROUP_IDENTIFIER,
      actions: [
        { label: 'Show', handler: () => router.push({ name: ROUTE_NAME_ASSET, query: { title_id: signalData.id } }) }
      ],
      position: 'bottom-right',
      icon: 'spi-bell'
    }, [ROUTE_NAME_ASSET, ROUTE_NAME_VIEW])

    const className = 'bg-neutral-tint-90 '
    const sentimentScore = Auxiliary.calculateSentimentScore(signalData.s || 0) / 100
    const sentimentIcon = Auxiliary.getIconClassBySentimentValue(sentimentScore)
    const sentimentIconColor = Auxiliary.getColorForSentimentScore(sentimentScore)
    addEvent({ time: signalData.t, label: signalData.s_n, titleId: signalData.id, className, buzz: signalData.b, sentimentScore, sentimentIcon, sentimentIconColor })
  }

  function handleSignalMessage (data: unknown, error: unknown) {
    if (error) {
      return
    }
    const signalData = data as Signal

    // Check for duplicate
    if (eventStream.value.find(ev => ev.time === signalData.t && ev.titleId === signalData.id)) {
      return
    }

    notificationService.notify({
      message: signalData.s_n,
      caption: signalData.title_name,
      group: SIGNAL_GROUP_IDENTIFIER,
      actions: [
        { label: 'Show', handler: () => router.push({ name: ROUTE_NAME_ASSET, query: { title_id: signalData.id } }) }
      ],
      position: 'bottom-right',
      icon: 'spi-bell'
    })

    let className = 'bg-neutral-tint-90'
    let icon
    if (signalData.s_n.startsWith('A.I. Alert')) {
      className = 'bg-yellow-tint-70'
      icon = 'spi-brain-circuit'
    } else {
      icon = 'spi-binoculars'
    }

    const sentimentScore = Auxiliary.calculateSentimentScore(signalData.s || 0) / 100
    const sentimentIcon = Auxiliary.getIconClassBySentimentValue(sentimentScore)
    const sentimentIconColor = Auxiliary.getColorForSentimentScore(sentimentScore)
    addEvent({ time: signalData.t, label: signalData.s_n, titleId: signalData.id, className, buzz: signalData.b, sentimentScore, sentimentIcon, sentimentIconColor, icon })
  }

  function addEvent (event : StreamEvent) {
    if (eventStream.value.length > 100) {
      eventStream.value.pop()
    }
    eventStream.value.unshift(event)
  }

  async function loadData (): Promise<void> {
    keyEvents.value.loading = true
    titles.value.loading = true
    exchanges.value.loading = true
    countries.value.loading = true
    preIPOCompanies.value.loading = true
    lists.value.loading = true
    sources.value.loading = true
    topBuzzTitleIds.value.loading = true
    topBuzzIndexIds.value.loading = true
    trendingKeyEvents.value.loading = true

    if (subscriptionIds.value.length > 0) {
      realtimeDataStore.unsubscribeTitleUpdates(subscriptionIds.value)
    }

    const requests : Promise<void>[] = [
      loadKeyEvents(),
      loadTitles(),
      loadPreIPO(),
      loadLists(),
      loadSectors(),
      loadExchanges(),
      loadCountries(),
      loadSources(),
      loadTopBuzzTitleIds(),
      loadTopBuzzIndexIds(),
      loadTrendingKeyEvents()
    ]

    await Promise.all(requests)

    isInitialized.value = true
  }

  async function loadKeyEvents () {
    const fetchedKeyEvents = await fetchKeyEvents()
    keyEvents.value = {
      loading: false,
      value: fetchedKeyEvents
    }
  }

  async function fetchKeyEvents (): Promise<{[key: number]: KeyEvent}> {
    const response = await api.get('/v6/key_events_v2?&add_icon=true')
    const fetchedKeyEvents: Array<{ key_event_id: number; name: string, icon: string }> = response.data.reduce(
      (carry: Array<{ key_event_id: number, name: string, icon: string }>,
        a: { key_events: Array<{ key_event_id: number, name: string, icon: string }> }
      ) =>
        carry.concat(a.key_events), [] as Array<{ key_event_id: number, name: string, icon: string }>
    )
    const keyEventCollection : {[key: number]: KeyEvent} = {}
    fetchedKeyEvents.forEach(keyEvent => {
      keyEventCollection[keyEvent.key_event_id] = { name: keyEvent.name, icon: keyEvent.icon, id: keyEvent.key_event_id }
    })
    return keyEventCollection
  }

  async function loadTrendingKeyEvents () {
    const fetchedTrendingKeyEvents = await fetchTrendingKeyEvents()
    trendingKeyEvents.value = {
      loading: false,
      value: fetchedTrendingKeyEvents
    }
  }

  async function fetchTrendingKeyEvents (): Promise<TrendingKeyEvents> {
    const response = await api.get('/v6/key_events/latest')
    const data = response.data
    const keyEventCount : TrendingKeyEvents = {}
    data.forEach((ds:any) => {
      if (ds.key_event_id === undefined) {
        return
      }
      if (keyEventCount[ds.key_event_id] !== undefined) {
        keyEventCount[ds.key_event_id] = keyEventCount[ds.key_event_id] + 1
      } else {
        keyEventCount[ds.key_event_id] = 1
      }
    })
    const entries = Object.entries(keyEventCount)
    entries.sort((a, b) => b[1] - a[1])
    const top10 = entries.slice(0, 10)
    const result = Object.fromEntries(top10)
    return result as {[key:number]:number}
  }

  async function loadExchanges () {
    const fetchedExchanges = await api.get('/v6/exchanges?&add_icon=true')
    exchanges.value = {
      loading: false,
      value: fetchedExchanges.data
    }
  }

  async function loadCountries () {
    const fetchedCountries = await api.get('/v6/countries?&add_icon=true')
    countries.value = {
      loading: false,
      value: fetchedCountries.data
    }
  }
  async function loadSources () {
    const fetchedSources = await api.get('/v6/sources?add_icon=true')
    sources.value = {
      loading: false,
      value: fetchedSources.data
    }
  }
  async function loadTopBuzzTitleIds () {
    const fetchedBuzzTitlesResponse = await api.post(
      '/v6/titles?limit=5&sort_by=buzz&partition=10minute&only_top_titles=1',
      []
    )
    const fetchedBuzzTitles = fetchedBuzzTitlesResponse.data as { id: number }[]
    const titleIds: number[] = fetchedBuzzTitles
      .filter(buzzTitle => buzzTitle.id !== undefined)
      .map(buzzTitle => buzzTitle.id)

    topBuzzTitleIds.value = {
      loading: false,
      value: titleIds
    }
    subscriptionIds.value.push(realtimeDataStore.subscribeTitleUpdates(titleIds))
  }

  async function loadTopBuzzIndexIds () {
    const fetchedBuzzIndexesResponse = await api.get('/v6/titles?limit=5&sort_by=buzz&partition=10minute&type=index')
    const fetchedBuzzIndexes = fetchedBuzzIndexesResponse.data as TitleInventoryInner[]
    const indexIds: number[] = fetchedBuzzIndexes
      .filter(buzzTitle => buzzTitle.id !== undefined)
      .map(buzzTitle => buzzTitle.id) as number[]

    topBuzzIndexIds.value = {
      loading: false,
      value: indexIds
    }
    subscriptionIds.value.push(realtimeDataStore.subscribeTitleUpdates(indexIds))
  }

  async function loadTitles () {
    const fetchedTitles = await fetchTitles()
    const titlesObject : {[key: number]: InventoryTitle} = {}
    fetchedTitles.forEach(title => {
      titlesObject[title.id] = title
    })

    titles.value = {
      loading: false,
      value: titlesObject
    }
  }

  async function fetchTitles (): Promise<InventoryTitle[]> {
    const response = await api.get('/v6/inventory?add_icon=true')
    return response.data
  }

  async function loadPreIPO () {
    const fetchedPreIPO = await fetchPreIPO()
    preIPOCompanies.value = {
      loading: false,
      value: fetchedPreIPO
    }
    if (fetchedPreIPO.length > 0) {
      subscriptionIds.value.push(realtimeDataStore.subscribeTitleUpdates(fetchedPreIPO.map(preIpo => preIpo.id)))
    }
  }

  async function fetchPreIPO (): Promise<TitleInformation[]> {
    const filterList : FilterSettingsList = []
    filterList.push({ field: 'type', comparator: 'eq', value: 'pre_ipo' })
    filterList.push({ field: filterV3FieldNames.country, comparator: 'gte', value: '' })

    const topTitles = await filterTitles(filterList, 100, 'buzz', '10minute')
    const titleIds = topTitles.map((title : any) => title.id)
    const titleInformation = await fetchInfosForTitles(titleIds)
    return Array.isArray(titleInformation) ? titleInformation : [titleInformation]
  }

  async function loadSectors () {
    const fetchedSectors = await fetchSectors()
    const sectorsObject : {[key: number]: InventorySector} = {}
    fetchedSectors.forEach(sector => {
      sectorsObject[sector.id] = sector
    })
    sectors.value = {
      loading: false,
      value: sectorsObject
    }
  }

  async function fetchSectors (): Promise<InventorySector[]> {
    const response = await api.get('/v6/sectors?add_icon=true')
    return response.data
  }

  async function loadLists () {
    const fetchedLists = await fetchLists()
    lists.value = {
      loading: false,
      value: fetchedLists
    }
  }

  async function fetchLists (): Promise<ListsInner[]> {
    const response = await api.get('/v6/lists?add_icon=true')
    return response.data
  }

  function translateKeyEvent (eventId: number): string {
    const keyEventName = keyEvents.value.value ? keyEvents.value.value[eventId]?.name : undefined

    if (!keyEventName) {
      return ''
    }
    const translationKey = 'MessageStatistics.keyEvent.' + keyEventName
    const translation = t(translationKey)
    return translation !== translationKey ? translation : keyEventName
  }
  function getKeyEventIconById (eventId: number): string|undefined {
    return keyEvents.value.value ? keyEvents.value.value[eventId]?.icon : undefined
  }
  function getKeyEventById (eventId: number): KeyEvent|undefined {
    return keyEvents.value.value ? keyEvents.value.value[eventId] : undefined
  }
  function getSectorIconById (sectorId: number): string|undefined {
    return sectors.value.value ? sectors.value.value[sectorId]?.icon : undefined
  }
  function getSectorNameById (sectorId: number): string|undefined {
    return sectors.value.value ? sectors.value.value[sectorId]?.name : undefined
  }
  function getSectorById (sectorId: number): InventorySector|undefined {
    return sectors.value.value ? sectors.value.value[sectorId] : undefined
  }

  function getListById (listId: number): ListsInner|undefined {
    return lists.value.value?.find(list => list.id === listId)
  }

  function getTitleNameById (titleId: number): string {
    return titles.value.value?.[titleId]?.n || ''
  }
  function getTitleById (titleId: number): InventoryTitle | undefined {
    return titles.value.value?.[titleId]
  }
  function getTitleIconById (titleId: number): string | undefined {
    return titles.value.value?.[titleId]?.icon
  }

  function getTitlesById (titleIds: number[]) : InventoryTitle[] {
    return titleIds.reduce((accumulatedTitles, titleId) => {
      const titleInfo = titles.value.value?.[titleId]
      if (titleInfo) {
        accumulatedTitles.push(titleInfo)
      }
      return accumulatedTitles
    }, [] as InventoryTitle[])
  }

  function getExchangeByName (exchangeName:string): ExchangeData |undefined {
    return exchanges.value.value?.find(exchange => exchange.name?.toUpperCase() === exchangeName.toUpperCase())
  }

  function getExchangeById (id: number): ExchangeData | undefined {
    return exchanges.value.value?.find(exchange => exchange.exchange_id === id)
  }

  function getSourceByName (name: string): Source | undefined {
    return sources.value.value?.find(source => source.source === name)
  }
  function getSourceIconByName (name: string, fallbackIcon = 'spi-globe'): string {
    return sources.value.value?.find(source => source.source === name)?.icon || fallbackIcon
  }

  function getCountryByName (countryName: string): ExtendedCountriesInner | undefined {
    return countries.value.value?.find(country => country.name === countryName)
  }

  function getCountryByUn (un: string): ExtendedCountriesInner | undefined {
    return countries.value.value?.find(country => country.un === un)
  }

  function getCountryIconByUn (un: string): string | undefined {
    return countries.value.value?.find(country => country.un === un)?.icon || 'spi-globe'
  }

  function createNameFromTitleFilter (titleFilter: ViewFilter): string {
    const elements: (string | undefined)[] = []
    elements.push(...titleFilter.country.map((x: string) => getCountryByUn(x)?.name))
    elements.push(...titleFilter.exchange.map((x: number) => getExchangeById(x)?.name))
    // TODO regions
    // elements.push(...titleFilter.region.map((x: string) => x));
    elements.push(...titleFilter.sector.map((x: number) => getSectorById(x)?.name))
    elements.push(...titleFilter.list.map((x: number) => getListById(x)?.n))
    elements.push(...titleFilter.asset.map((x: number) => getTitleById(x)?.n))
    elements.push(...titleFilter.keyEvent.map((x: number) => getKeyEventById(x)?.name))
    elements.push(...titleFilter.type.map((x: string) => x.replace('_', ' ').split(' ').map(word => capitalize(word)).join(' ')))
    return elements.filter(x => x !== undefined).join(', ')
  }

  return {
    isInitialized,
    eventStream,
    getSectorIconById,
    getSectorNameById,
    getSectorById,
    translateKeyEvent,
    getKeyEventIconById,
    getKeyEventById,
    getTitleNameById,
    getTitleById,
    getTitlesById,
    getTitleIconById,
    preIPOCompanies,
    getExchangeByName,
    getExchangeById,
    getCountryByName,
    getCountryByUn,
    getCountryIconByUn,
    getSourceIconByName,
    getSourceByName,
    sectors,
    exchanges,
    countries,
    keyEvents,
    lists,
    getListById,
    createNameFromTitleFilter,
    trendingKeyEvents,
    topBuzzTitleIds,
    topBuzzIndexIds
  }
})
