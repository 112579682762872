type RuntimeConfig = {
  APP_BASE_URL: string
  API_BASE_URL: string
  API_WEBSOCKET_URL: string
  PLAUSIBLE_API_BASE_URL: string
  PLAUSIBLE_ENABLED: boolean
  NODE_ENV: 'dev' | 'staging' | 'production'
}

// Use DEV environment as default configuration - not the PROD env
const defaultRuntimeConfig: RuntimeConfig = {
  APP_BASE_URL: 'dev.stockpulse.ai',
  API_BASE_URL: 'https://api.dev.stockpulse.ai',
  API_WEBSOCKET_URL: 'https://ws.dev.stockpulse.ai',
  NODE_ENV: 'dev',
  PLAUSIBLE_API_BASE_URL: 'https://plausible.stockpulse.ai',
  PLAUSIBLE_ENABLED: false
}

const useRuntimeConfig = (): RuntimeConfig => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.runtimeConfig || defaultRuntimeConfig
}

export { useRuntimeConfig }
