import { getTodaysTimestamp } from './DataTransformer'

export const REPORT_MODEL_DAILY = 4
export const REPORT_MODEL_HOURLY = 9
export const REPORT_MODEL_10_MIN = 2

export function getReportModelId (startDate: Date, endDate: Date, isExtendedTimeRange = true): number {
  const intervalLimitForReportModelHourly = (isExtendedTimeRange ? 31 : 8) * 24 * 3600 * 1000
  const intervalLimitForReportModel10min = (isExtendedTimeRange ? 8 : 1) * 24 * 3600 * 1000

  const timeInterval = (getTodaysTimestamp(endDate.getTime()) - getTodaysTimestamp(startDate.getTime())) / 1000

  if (timeInterval <= intervalLimitForReportModel10min) {
    return REPORT_MODEL_10_MIN
  }

  if (timeInterval <= intervalLimitForReportModelHourly) {
    return REPORT_MODEL_HOURLY
  }

  return REPORT_MODEL_DAILY
}
