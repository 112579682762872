import { TrackingAction, TrackingContext } from 'src/boot/plausible'
import { AbstractEntity } from 'src/entities/AbstractEntity'
import { RouteLocationRaw } from 'vue-router'
import { ROUTE_NAME_AUTHOR } from 'src/router/routes'
import { EntityEnum } from 'src/helper/EntityHelper'

export class AuthorEntity extends AbstractEntity {
  protected readonly defaultIcon: string = 'spi-user'
  private readonly source: string
  public constructor (name: string, source: string) {
    super(-1, EntityEnum.AUTHOR, name)
    this.source = source
  }

  public getId (): number {
    throw new Error('Authors do not have an ID.')
  }

  public getNameFromInventory (): string {
    return ''
  }

  public getIcon (): string {
    return this.defaultIcon
  }

  public getSubType (): string {
    return this.source
  }

  public getTrackingAction (): TrackingAction {
    return TrackingAction.OPEN_AUTHOR
  }

  public getTrackingContext (): TrackingContext {
    return { authorName: this.getName(), authorId: this.id, source: this.source }
  }

  public getRouteOptions (): RouteLocationRaw {
    return { name: ROUTE_NAME_AUTHOR, query: { source: this.source, name: this.name } }
  }
}
