import { TrackingAction, TrackingContext } from 'src/boot/plausible'
import { AbstractEntity } from 'src/entities/AbstractEntity'
import { RouteLocationRaw } from 'vue-router'
import { ROUTE_NAME_ASSET } from 'src/router/routes'
import { useCommonStore } from 'stores/common-store'

export class AssetEntity extends AbstractEntity {
  protected readonly defaultIcon: string = 'spi-building'
  public getNameFromInventory (): string {
    return useCommonStore().getTitleNameById(this.id)
  }

  public getIcon (): string {
    return useCommonStore().getTitleIconById(this.id) || this.defaultIcon
  }

  public getSubType (): string {
    return useCommonStore().getTitleById(this.id)?.type || ''
  }

  public getTrackingAction (): TrackingAction {
    return TrackingAction.OPEN_ASSET
  }

  public getTrackingContext (): TrackingContext {
    return { titleName: this.getName(), titleId: this.id, titleType: this.getSubType() }
  }

  public getRouteOptions (): RouteLocationRaw {
    return { name: ROUTE_NAME_ASSET, query: { title_id: this.id } }
  }
}
