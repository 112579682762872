import { AssetEntity } from 'src/entities/AssetEntity'
import { IndexEntity } from 'src/entities/IndexEntity'
import { CryptoCurrencyEntity } from 'src/entities/CryptoCurrencyEntity'
import { ExchangeEntity } from 'src/entities/ExchangeEntity'
import { KeyEventEntity } from 'src/entities/KeyEventEntity'
import { SectorEntity } from 'src/entities/SectorEntity'
import { AbstractEntity } from 'src/entities/AbstractEntity'

// Always add case in createEntity function below, when adding new enum value
export enum EntityEnum {
    ASSET = 'asset',
    INDEX = 'index',
    CRYPTO_CURRENCY = 'crypto_currency',
    SECTOR = 'sector',
    COUNTRY = 'country',
    EXCHANGE = 'exchange',
    AUTHOR = 'author',
    KEY_EVENT = 'key_event',
    PRE_IPO = 'pre_ipo',
    VIEW = 'view',
    TYPE = 'type'
}

export function createEntity (id: number, type: EntityEnum, name?: string): AbstractEntity {
  switch (type) {
  case EntityEnum.ASSET:
    return new AssetEntity(id, type, name)
  case EntityEnum.INDEX:
    return new IndexEntity(id, type, name)
  case EntityEnum.CRYPTO_CURRENCY:
    return new CryptoCurrencyEntity(id, type, name)
  case EntityEnum.EXCHANGE:
    return new ExchangeEntity(id, type)
  case EntityEnum.KEY_EVENT:
    return new KeyEventEntity(id, type)
  case EntityEnum.SECTOR:
    return new SectorEntity(id, type)
  case EntityEnum.VIEW:
    throw new Error(
      'ViewEntity has no default constructor with ID and type - use "new ViewEntity(id, name, viewFilter)" instead.'
    )
  case EntityEnum.AUTHOR:
    throw new Error(
      'AuthorEntity has no default constructor with ID and type - use "new AuthorEntity(name, source)" instead.'
    )
  case EntityEnum.COUNTRY:
    throw new Error(
      'CountryEntity has no default constructor with ID and type - use "new CountryEntity(uncode)" instead.'
    )
  case EntityEnum.PRE_IPO:
    throw new Error(
      'PreIPOEntity has no default constructor with ID and type - use "new PreIPOEntity(id, name, country)" instead.'
    )
  case EntityEnum.TYPE:
    throw new Error('TypeEntity has no default constructor with ID and type - use "new TypeEntity(name)" instead.')
  }
}

export function getEntityType (titleId: number): EntityEnum {
  if (titleId >= 90_000 && titleId < 300_000) {
    return EntityEnum.CRYPTO_CURRENCY
  }

  if (titleId >= 1_000_000 && titleId < 1_100_000) {
    return EntityEnum.INDEX
  }
  return EntityEnum.ASSET
}
