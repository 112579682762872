import { Ref } from 'vue'
import { AsyncData } from 'components/models'
import { Message, SearchMessagesStatistics } from '@stockpulse/typescript-axios'
import { sanitizeString } from 'src/helper/StringHelper'
import NotificationService from 'src/services/NotificationService'
import { useI18n } from 'vue-i18n'
import { useCommonStore } from 'stores/common-store'

export default class ThrottlingMessageProcessor {
  private messages: Ref<AsyncData<Message[]>>
  private messageStatistics: Ref<AsyncData<SearchMessagesStatistics>>
  private notificationService: NotificationService
  private origin: string
  private t
  private messageProcessingThrottleMS: number
  constructor (messagesRef: Ref<AsyncData<Message[]>>,
    messageStatisticsRef: Ref<AsyncData<SearchMessagesStatistics>>,
    notificationService: NotificationService,
    origin: string,
    messageProcessingThrottleMS: number) {
    this.messages = messagesRef
    this.messageStatistics = messageStatisticsRef
    this.notificationService = notificationService
    this.origin = origin
    this.messageProcessingThrottleMS = messageProcessingThrottleMS
    const { t } = useI18n()
    this.t = t
  }

  public processMessages = (allNewMessages: Message[]) => {
    const newMessages = allNewMessages.reduce((acc: Message[], obj: Message) => {
      return acc.some(item => item.url === obj.url) ? acc : [...acc, obj]
    }, []).map(message => {
      return {
        ...message,
        t: Math.round((new Date()).getTime() / 1000)
      }
    })

    let posCount = 0
    let negCount = 0
    let neuCount = 0

    const countBySource = newMessages.reduce((sourceCount: { [key: string]: number }, next) => {
      sourceCount[next.so] = (sourceCount[next.so] || 0) + 1
      return sourceCount
    }, {})

    const sortedSources = Object.entries(countBySource)
      .sort((a, b) => b[1] - a[1])
      .map(([source, count]) => ({ source, count }))

    let sourceString = sanitizeString(newMessages[0].so)
    if (newMessages.length > 1) {
      sourceString = sortedSources.slice(0, 2).map(x => sanitizeString(x.source)).join(', ')
      if (sortedSources.length > 2) {
        sourceString = `${sourceString} +${sortedSources.length - 2}`
      }
    }

    // sanitize Strings since we enable html mode
    const text = newMessages.length > 1
      ? this.t('Notifications.newMessagesInSources', { source: `<b>${sourceString}</b>` })
      : this.t('Notifications.newMessage', {
        name: `<b>${sanitizeString(newMessages[0].a)}</b>`,
        source: `<b>${sourceString}</b>`
      })

    newMessages.forEach(message => {
      if (!message.s) {
        neuCount++
      } else if (message.s > 0) {
        posCount++
      } else if (message.s < 0) {
        negCount++
      }
      this.notificationService.notify({
        position: 'bottom-right',
        message: text,
        group: 'message',
        html: true,
        // Real animation timeout seems to be longer than the provided value.
        // We need an offset that the messages do not stack infinitely.
        timeout: this.messageProcessingThrottleMS - 1000,
        actions: [
          {
            label: 'Show',
            handler: () => {
              const element = document.getElementById('messageList')
              if (!element) {
                return
              }
              element.scrollIntoView({ behavior: 'smooth' })
            }
          }
        ],
        icon: useCommonStore().getSourceIconByName(message.so, 'spi-envelope')
      },
      [this.origin])
    })
    // Update message counts
    if (this.messageStatistics.value.value && this.messageStatistics.value.value.counts && this.messageStatistics.value.value?.counts?.messages) {
      this.messageStatistics.value.value.counts.messages++
      this.messageStatistics.value.value.counts.pos = this.messageStatistics.value.value.counts.pos + posCount
      this.messageStatistics.value.value.counts.neg = this.messageStatistics.value.value.counts.neg + negCount
      this.messageStatistics.value.value.counts.neu = this.messageStatistics.value.value.counts.neu + neuCount
    }

    if (!this.messages.value.value) {
      this.messages.value.value = newMessages
    } else if (this.messages.value.value?.length < 100) {
      this.messages.value.value?.unshift(...newMessages)
    } else if (this.messages.value.value) {
      const slicedMessages = this.messages.value.value?.slice(0, 50)
      slicedMessages.unshift(...newMessages)
      this.messages.value.value = slicedMessages
    }
  }
}
