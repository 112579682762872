import { Router } from 'vue-router'
import { Notify, QNotifyCreateOptions } from 'quasar'

export default class NotificationService {
  private router: Router
  private DEFAULT_DISPLAY_TIME = 5000

  constructor (router: Router) {
    this.router = router
  }

  notify (notificationOptions: QNotifyCreateOptions|string, origin?: string[]) {
    // The path includes /
    if (!origin || origin.includes(this.router.currentRoute.value.path.slice(1))) {
      if (typeof notificationOptions === 'object' && notificationOptions.timeout === undefined) {
        notificationOptions.timeout = this.DEFAULT_DISPLAY_TIME
      }

      Notify.create(notificationOptions)
    }
  }
}
