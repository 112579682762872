import { defineStore, storeToRefs } from 'pinia'
import { timeRangeStore } from './time-range-store'
import { computed, ComputedRef, Ref, ref, watch } from 'vue'
import {
  EntityType,
  Message,
  PersonV6,
  QuotesForTitlesQuotesInner,
  SearchMessagesStatistics,
  TitleInformation
} from '@stockpulse/typescript-axios'
import { AsyncData } from 'components/models'
import { sub } from 'date-fns'
import { sourceFilterStore } from 'stores/source-filter-store'
import {
  api, axiosRequestManager,
  extractReferencedPeople, fetchChartData,
  fetchInfosForTitles, fetchQuoteChartData, fetchSolrMessages,
  fetchStatistics,
  fetchSummary,
  fetchTitleChartData, fetchTitleInfos,
  fetchTopicsList,
  filterTitles, MessageFilter,
  ViewFilter
} from 'boot/axios'
import { webSocketStore } from 'stores/web-socket-store'
import { useRouter } from 'vue-router'
import NotificationService from 'src/services/NotificationService'
import { ROUTE_NAME_VIEW } from 'src/router/routes'
import { KeyEventsV2ForTitles } from '@stockpulse/typescript-axios/types'
import { KeyEventsV2ApiFactory } from '@stockpulse/typescript-axios/dist/client/key-events-v2-api'
import { Auxiliary } from 'src/helper/Auxiliary'
import * as StringHelper from 'src/helper/StringHelper'
import { getEntityType } from 'src/helper/StringHelper'
import { useI18n } from 'vue-i18n'
import ThrottlingMessageProcessor from 'src/services/ThrottlingMessageProcessor'
import { SubscriptionId, useRealTimeDataStore } from 'stores/realtime-data-store'
import { useBufferedThrottleFn } from 'src/helper/BufferedThrottle'
import { getReportModelId, REPORT_MODEL_10_MIN, REPORT_MODEL_HOURLY } from 'src/helper/ReportHelper'
import { prepareMessageHistoryChartData, StackItem } from 'src/helper/DataTransformer'
import { AxiosRequestConfig } from 'axios/index'

export interface Title {
  name: string,
  id: number,
  type: EntityType,
  buzz: number,
  buzz_open: number
}

export enum ListEntityType {
  LIST = 'list',
  SECTOR = 'sector',
  WATCHLIST = 'watchlist',
  EXCHANGE = 'exchange',
  REGION = 'region',
  COUNTRY = 'country',
  ASSET = 'asset',
  KEYEVENT = 'keyEvent',
  TYPE = 'type'
}

export interface TitleChartData {
  titleId: number,
  titleName: string,
  chartDataSeries: (number | string)[][],
  minValue: number,
  maxValue: number,
  lastQuote: QuotesForTitlesQuotesInner,
  secondLastQuote: QuotesForTitlesQuotesInner,
  currency: string
}

export interface EntityInformation {
  name: string
}

export const useListViewStore = defineStore('listView', () => {
  const timeRange = timeRangeStore()
  const sourceFilter = sourceFilterStore()
  const realtimeDataStore = useRealTimeDataStore()
  const { titlesRealtimeData } = storeToRefs(realtimeDataStore)

  const webSocket = webSocketStore()
  const router = useRouter()
  const notificationService = new NotificationService(router)
  const { t } = useI18n()

  const reloadAllHash = ref('')
  const reloadTitlesHash = ref('')
  const reloadMessagesHash = ref('')

  // Store Properties
  const entityType: Ref<string|undefined> = ref()

  const entityInformation = ref<AsyncData<EntityInformation>>({ loading: true })

  const titleFilter = ref<ViewFilter>()
  const messageFilter : Ref<MessageFilter> = ref({
    messageType: [],
    messageLanguage: [],
    authorType: [],
    keyEvent: []
  })

  const searchQuery = ref<string>('')
  const titlesFromTitleFilter = ref<AsyncData<Title[]>>({ loading: true, value: [] })
  const titleIdsFromSolrMessages : Ref<AsyncData<number[]>> = ref({ loading: true, value: undefined })

  const keyEventsForTitles = ref<AsyncData<KeyEventsV2ForTitles>>({ loading: true })

  const messageStatistics = ref<AsyncData<SearchMessagesStatistics>>({ loading: true, value: undefined })
  const messages = ref<AsyncData<Message[]>>({ loading: true, value: [] })
  const keyEventsV2ApiFactory = KeyEventsV2ApiFactory(undefined, '/v6', api)

  // List only
  const indexTitleInformation = ref<AsyncData<TitleInformation|undefined>>({ loading: true })
  const indexChartData = ref<AsyncData<{ history: Array<{ t:number, Buzz:number, Sentiment:number }>, reportModelId: number }>>({ loading: true, value: { history: [], reportModelId: -1 } })
  const indexQuoteChartData = ref<AsyncData<{ history: Array<QuotesForTitlesQuotesInner>, reportModelId: number }>>({ loading: true, value: { history: [], reportModelId: -1 } })

  // Websocket
  const socketListeners = ref<CallableFunction[]>([])
  const subscriptionIds: Ref<SubscriptionId[]> = ref([])

  const topicsList = ref<AsyncData<string[]>>({ loading: true, value: [] })
  const executiveSummary = ref<AsyncData<{ summary: string, messages: Array<Message> }>>({
    loading: true,
    value: { summary: '', messages: [] }
  })
  const referencedPeople = ref<AsyncData<{ label: string, value: number }[]>>({ loading: true, value: [] })
  const referencedPeopleInfos = ref<AsyncData<PersonV6[]>>({ loading: true, value: [] })
  const top5ChartData = ref<AsyncData<TitleChartData[]>>({ loading: true, value: [] })

  watch(
    () => messageFilter,
    () => {
      loadData()
    }, { deep: true }
  )

  // TODO_NEXT Check filtering!!!
  // Titles get computed by titles fetched from TitleFilter and Titles with Messages
  const titles = computed<AsyncData<Title[]>>(() => {
    if (titlesFromTitleFilter.value.loading || titleIdsFromSolrMessages.value.loading) {
      return { loading: true, value: [] }
    }

    if (titleIdsFromSolrMessages.value.value === undefined) {
      return { loading: false, value: titlesFromTitleFilter.value.value }
    }

    const filteredTitles = titlesFromTitleFilter.value.value?.filter(title => titleIdsFromSolrMessages.value.value?.includes(title.id))
    if (filteredTitles === undefined) {
      return { loading: false, value: [] }
    } else {
      return { loading: false, value: filteredTitles.slice(0, 100) as Title[] }
    }
  })

  async function setFilter (list: number[], exchange: number[], sector: number[], region: string[], country: string[], keyEvent: number[], asset: number[], type: EntityType[], aSearchQuery: string): Promise<void> {
    titleFilter.value = {
      list,
      exchange,
      sector,
      region,
      country,
      keyEvent,
      asset,
      type
    }
    searchQuery.value = aSearchQuery
    await loadData()
  }

  const isTitleFilterEmpty = computed(() => {
    if (titleFilter.value === undefined) {
      return false
    }
    return Object.values(titleFilter.value).reduce((total, currentArray : Array<any>) => total + currentArray.length, 0) === 0
  })

  const treeData = computed(() => {
    if (!titles.value.value || titles.value.loading) {
      return []
    }
    // We do not want to use the ref of titles!
    const titlesForBuzzSentiment = Array.from(titles.value.value)
    return Auxiliary.createTreeDataFromTitlesBuzzSentiment(titlesForBuzzSentiment, titlesRealtimeData.value)
  })

  async function getReloadMessagesHash ():Promise<string> {
    return await StringHelper.createIdentifier(sourceFilter.activeSourceValue, messageFilter.value)
  }
  async function getReloadAllHash ():Promise<string> {
    return await StringHelper.createIdentifier(timeRange.selectedTimeRange, searchQuery.value)
  }
  async function getReloadTitlesHash ():Promise<string> {
    return await StringHelper.createIdentifier(titleFilter.value)
  }

  async function loadData (): Promise<void> {
    const newReloadAllHash = await getReloadAllHash()
    const newReloadtitlesHash = await getReloadTitlesHash()
    const newReloadMessagesHash = await getReloadMessagesHash()

    const reloadAll = reloadAllHash.value !== newReloadAllHash
    const reloadTitles = reloadTitlesHash.value !== newReloadtitlesHash
    const reloadMessages = reloadMessagesHash.value !== newReloadMessagesHash

    if (!reloadAll && !reloadTitles && !reloadMessages) {
      return
    }

    reloadAllHash.value = newReloadAllHash
    reloadTitlesHash.value = newReloadtitlesHash
    reloadMessagesHash.value = newReloadMessagesHash
    if (reloadAll && reloadTitles) {
      axiosRequestManager.cancelRequests()
      titlesFromTitleFilter.value.loading = true
      topicsList.value.loading = true
      executiveSummary.value.loading = true
      referencedPeople.value.loading = true
      entityInformation.value.loading = true
      indexChartData.value.loading = true
      indexQuoteChartData.value.loading = true
    } else {
      const doNotCancelFilter = (request: AxiosRequestConfig) => {
        if (!request.url?.includes('messages/search')) {
          return false
        }
        if (typeof request.data !== 'string') {
          return false
        }
        if (request.data.includes('"include_exec_summary":true') || request.data.includes('"include_topics_list":true')) {
          return true
        }
        return false
      }
      axiosRequestManager.cancelRequests(doNotCancelFilter)
    }

    messageStatistics.value.loading = true
    messages.value.loading = true
    keyEventsForTitles.value.loading = true

    const requests: Promise<void>[] = []

    if (subscriptionIds.value.length > 0) {
      realtimeDataStore.unsubscribeTitleUpdates(subscriptionIds.value)
    }

    entityType.value = titleFilter.value && getEntityType(titleFilter.value)

    if (!isTitleFilterEmpty.value && reloadTitles) {
      if (reloadAll) {
        const fetchedTitles = await fetchTitles()
        titlesFromTitleFilter.value = {
          loading: false,
          value: fetchedTitles
        }
      }
    }
    titleIdsFromSolrMessages.value.loading = false

    // We need to wait for the Statistics to get the titlesToDisplay
    await loadStatistics()

    // Handle no titles found
    if (!titles.value?.value?.length) {
      titlesFromTitleFilter.value.loading = false
      topicsList.value.loading = false
      executiveSummary.value.loading = false
      referencedPeople.value.loading = false
      entityInformation.value.loading = false
      indexChartData.value.loading = false
      indexQuoteChartData.value.loading = false
      titleIdsFromSolrMessages.value.loading = false
      messageStatistics.value.loading = false
      messages.value.loading = false
      keyEventsForTitles.value.loading = false
      return
    }

    if (reloadAll) {
      if (!titlesFromTitleFilter.value.loading && titlesFromTitleFilter.value.value) {
        subscriptionIds.value.push(realtimeDataStore.subscribeTitleUpdates(titlesFromTitleFilter.value.value.map(title => title.id)))
      }

      // Subscribe to Titles
      unsubscribeFromWebsocket()

      subscribeToWebsocketForTitleMessages()
      requests.push(loadTopicsList())
      requests.push(loadSummary())

      // Handle Index differently
      if (entityType.value === ListEntityType.LIST) {
        requests.push(loadIndexChartData())
        requests.push(loadIndexInformation())
      } else {
        indexChartData.value = {
          loading: false,
          value: undefined
        }
        indexQuoteChartData.value = {
          loading: false,
          value: undefined
        }
        indexTitleInformation.value = { loading: false }
      }
    }

    requests.push(loadTitleKeyEvents())

    await Promise.all(requests)
  }

  const messageProcessingThrottleMS = 6000
  const throttlingMessageProcessor = new ThrottlingMessageProcessor(messages,
    messageStatistics, notificationService, ROUTE_NAME_VIEW, messageProcessingThrottleMS)
  const throttledMessageProcessing = useBufferedThrottleFn(throttlingMessageProcessor.processMessages, messageProcessingThrottleMS)

  const messageHistoryChartData: ComputedRef<{ loading: boolean } & { history?: Array<StackItem> }> = computed(() => {
    return prepareMessageHistoryChartData(messageStatistics.value)
  })

  async function fetchTitles (): Promise<Title[]> {
    if (titleFilter.value === undefined) {
      return []
    }
    // TODO_NEXT Remove KeyEvent sorting if this new filter mechanism is approved.
    const titleFilterWithoutKeyEvent : ViewFilter = { ...titleFilter.value }
    titleFilterWithoutKeyEvent.keyEvent = []
    const filterList = Auxiliary.getFilterSettingsListByTitleFilter(titleFilterWithoutKeyEvent)

    // Add name and buzz fields to selection
    filterList.push({ field: 'n', comparator: 'gte', value: '0' })
    filterList.push({ field: 'buzz.2', comparator: 'gte', value: 0 })
    filterList.push({ field: 'buzz.4', comparator: 'gte', value: 0 })

    const sortBy = 'buzz'
    const partition : string|undefined = '10minute'

    // TODO_NEXT Remove KeyEvent sorting if this new filter mechanism is approved.
    // If the view includes an keyEvent filter we sort by key_events
    /* if (titleFilter.value?.keyEvent.length) {
      sortBy = 'key_events'
      partition = undefined
    } */
    const fetchedTitles = await filterTitles(filterList, 1000, sortBy, partition)

    return fetchedTitles
      .filter((title : any) => title.n && title.id && title.type)
      .map((title : any) => {
        return {
          name: title.n as string,
          id: title.id as number,
          type: title.type as EntityType,
          // Buzz for report model 2 (10_minutes) is the current buzz ("Realtime")
          buzz: title['buzz.2'],
          // Buzz for report model 4 (daily) is the opening buzz for today
          buzz_open: title['buzz.4']
        }
      })
  }

  async function loadTitleKeyEvents () {
    const titleIdent = titles.value.value?.map(title => title.id.toString()).join(',')
    if (!titleIdent) {
      return
    }

    const startDate = timeRange.getCurrentStartDate()
    const endDate = timeRange.getCurrentEndDate()
    const reportModelId = getReportModelId(startDate, endDate, false)

    const fetchedKeyEvents = await keyEventsV2ApiFactory.getKeyEventsV2ForTitles(
      titleIdent,
      // TODO_NEXT Fix for 10 Minute KeyEventData. reportModelId === REPORT_MODEL_10_MIN || reportModelId === REPORT_MODEL_HOURLY ? '10minute' : 'daily_5am',
      'daily_5am',
      timeRange.getApiTimestamp(startDate),
      timeRange.getApiTimestamp(endDate)
    )

    keyEventsForTitles.value = {
      loading: false,
      value: fetchedKeyEvents.data
    }
  }

  async function loadStatistics (): Promise<void> {
    const titleIds = titlesFromTitleFilter.value.value?.map(title => title.id) ?? []
    titleIdsFromSolrMessages.value.loading = true
    const keyEventIds = [...messageFilter.value.keyEvent]
    if (titleFilter.value?.keyEvent?.length) {
      keyEventIds.push(...titleFilter.value?.keyEvent)
    }

    const statisticsData = await fetchStatistics(
      timeRange.getCurrentStartDate(),
      timeRange.getCurrentEndDate(),
      titleIds,
      sourceFilter.activeSourceValue,
      keyEventIds,
      searchQuery.value,
      messageFilter.value.messageLanguage,
      messageFilter.value.messageType,
      100
    )

    messageStatistics.value = {
      loading: false,
      value: statisticsData.statistics
    }

    // We have to wait for the statistics to load the referenced people
    const top10Entities = messageStatistics.value.value?.top10?.entities

    // If there is no titleFilter we need to determine the top titles
    if (isTitleFilterEmpty.value && messageStatistics.value.value?.top10?.titles) {
      const top10TitleIds = Object.keys(messageStatistics.value.value?.top10?.titles).map(Number)
      let titleInfos = await fetchInfosForTitles(top10TitleIds)
      if (!Array.isArray(titleInfos)) {
        titleInfos = [titleInfos]
      }
      titlesFromTitleFilter.value = {
        loading: false,
        value: titleInfos.map(titleInfo => {
          return {
            name: titleInfo.n,
            id: titleInfo.id,
            type: titleInfo.type,
            buzz: titleInfo.buzz['2'],
            buzz_open: titleInfo.buzz['4']
          }
        })
      }
    }

    const top100Titles = messageStatistics.value.value?.top10?.titles
    if (titleFilter.value?.keyEvent.length || messageFilter.value.messageType.length || messageFilter.value.keyEvent.length || messageFilter.value.messageLanguage.length || messageFilter.value.authorType.length) {
      titleIdsFromSolrMessages.value = {
        loading: false,
        value: top100Titles !== undefined ? Object.keys(top100Titles).map(Number) : []
      }
      console.log('Filter Titles with SOLR')
    } else {
      titleIdsFromSolrMessages.value = {
        loading: false,
        value: titleIds
      }
    }

    messages.value = {
      loading: false,
      value: statisticsData.messages?.filter(message =>
        message.titles?.some(messageTitle =>
          titles.value.value?.some(title => title.id === messageTitle.title_id))
      )
    }

    let fetchedReferencedPeople
    if (top10Entities) {
      fetchedReferencedPeople = await extractReferencedPeople(top10Entities)
    }

    referencedPeople.value = {
      loading: false,
      value: fetchedReferencedPeople?.statistics
    }

    referencedPeopleInfos.value = {
      loading: false,
      value: fetchedReferencedPeople?.entities
    }

    // TODO use correct type
    if (entityType.value === ListEntityType.LIST) {
      const top10Titles = titles.value.value?.slice(0, 5)?.map(title => title.id)
      if (!top10Titles) {
        top5ChartData.value = {
          loading: false,
          value: []
        }
        return
      }
      await loadTop5TitleChartData(top10Titles)
    } else {
      top5ChartData.value = {
        loading: false,
        value: []
      }
    }
  }

  async function loadTopicsList (): Promise<void> {
    const fetchedTopicsList = await fetchTopicsList(
      timeRange.getCurrentStartDate(),
      timeRange.getCurrentEndDate(),
      titles.value.value ? titles.value.value?.map(title => title.id) : [],
      sourceFilter.activeSourceValue
    )
    topicsList.value = {
      loading: false,
      value: fetchedTopicsList.topics_list
    }
  }

  async function loadSummary (): Promise<void> {
    if (titles.value.value === undefined || titles.value.value?.length === 0) {
      executiveSummary.value = {
        loading: false
      }
      return
    }
    const fetchedSummary = await fetchSummary(
      timeRange.getCurrentStartDate(),
      timeRange.getCurrentEndDate(),
      titles.value.value?.map(title => title.id)
    )
    executiveSummary.value = {
      loading: false,
      value: {
        summary: fetchedSummary.executive_summary,
        messages: fetchedSummary.messages
      }
    }
  }

  async function loadIndexChartData (startDateIn? : Date, endDateIn?: Date, isExtendedTimeRange = true): Promise<void> {
    const startDate = startDateIn || timeRange.getCurrentExtendedStartDate()
    const endDate = endDateIn || timeRange.getCurrentEndDate()
    const listId = titleFilter.value?.list[0]
    if (listId === undefined) {
      indexChartData.value = {
        loading: false,
        value: undefined
      }
      indexQuoteChartData.value = {
        loading: false,
        value: undefined
      }
      return
    }
    const fetchedChartData = await fetchChartData(startDate, endDate, [listId], isExtendedTimeRange)
    indexChartData.value = {
      loading: false,
      value: fetchedChartData
    }

    const fetchedQuoteChartData = await fetchQuoteChartData(
      startDate,
      endDate,
      [listId],
      isExtendedTimeRange
    )
    indexQuoteChartData.value = {
      loading: false,
      value: fetchedQuoteChartData
    }
  }

  async function loadIndexInformation (): Promise<void> {
    const listId = titleFilter.value?.list[0]
    if (listId === undefined) {
      indexTitleInformation.value = { loading: false }
      return
    }
    const fetchedTitleInformation = await fetchTitleInfos(listId)

    // Set Currency to PTS
    indexTitleInformation.value = {
      loading: false,
      value: {
        ...fetchedTitleInformation,
        currency: 'PTS'
      }
    }
  }

  async function loadNextMessages (authorScore?:number): Promise<number> {
    const titleIds = titles.value.value?.map(title => title.id)

    let startDate = timeRange.getCurrentStartDate()
    let endDate = timeRange.getCurrentEndDate()

    if (messages.value.value && messages.value.value.length > 0) {
      const oldestMessage = messages.value.value.sort((a, b) => a.t - b.t)[0].t
      startDate = sub(oldestMessage * 1000, { days: 7 }) // JLDEBUG müssen wir hier überhaupt das StartDate setzen?
      endDate = sub(oldestMessage * 1000, { seconds: 1 })
    }

    const nextMessages = await fetchSolrMessages(
      startDate,
      endDate,
      titleIds || [],
      sourceFilter.activeSourceValue,
      messageFilter.value.keyEvent,
      messageFilter.value.messageType,
      searchQuery.value,
      messageFilter.value.messageLanguage,
      authorScore
    )

    const fetchedMessages: Message[] = []
    nextMessages.forEach((message: Message) => fetchedMessages.push(message))
    const uniqueMessagesMap = new Map(fetchedMessages.map(obj => [obj.message_id, obj]))
    const uniqueMessages = [...uniqueMessagesMap.values()]
    if (uniqueMessages.length === 0) {
      return 0
    }

    if (!messages.value.value) {
      messages.value.value = uniqueMessages
    } else {
      messages.value.value.push(...uniqueMessages)
    }

    return uniqueMessages.length
  }

  function resetMessages () : void {
    messages.value.value = []
  }

  function subscribeToWebsocketForTitleMessages () {
    if (!titlesFromTitleFilter.value.value) {
      return
    }

    const unsubscribeMessagesFunction = webSocket.emitSubscribeTitlesMessages(
      titlesFromTitleFilter.value.value?.map(title => title.id),
      handleWebsocketMessage
    )
    socketListeners.value.push(unsubscribeMessagesFunction)
  }

  function handleWebsocketMessage (data: unknown, error: unknown): void {
    if (error) {
      return
    }
    const message = data as any
    if (!message || !messages.value.value) {
      return
    }

    if (
      (sourceFilter.activeSourceValue !== '' && message.so !== sourceFilter.activeSourceValue) ||
      !message.titles?.some((messageTitleId: any) => titles.value.value?.map(title => title.id).includes(messageTitleId.title_id)) ||
      // TODO_NEXT Is there a better way to do it?
      ((searchQuery.value !== '' && (!message.body?.toLowerCase().includes(searchQuery.value) && !message.sub?.toLowerCase().includes(searchQuery.value))))
    ) {
      return
    }
    if (messages.value.value.find(mess =>
      mess.url === message.url
    )) {
      return
    }
    throttledMessageProcessing([message])
  }

  function unsubscribeFromWebsocket () {
    socketListeners.value.forEach(unsubscribeCallback => unsubscribeCallback())
  }

  async function loadTop5TitleChartData (titleIds : number[]): Promise<void> {
    if (messageStatistics.value.value?.top10?.titles === undefined) {
      return
    }
    const top5TitleChartData: TitleChartData[] = []
    // We load all the infos that we have at least 5 quotes
    const top10TitleIds = titleIds
      // We filter by list of current titles
      .filter(titleId => titlesFromTitleFilter.value.value?.find(title => title.id === titleId))
    // TODO special case
    if (titleFilter.value?.list !== undefined && titleFilter.value?.list?.length > 0) {
      top10TitleIds.unshift(...titleFilter.value?.list)
    }
    if (top10TitleIds.length > 0) {
      let top10TitleInfos = await fetchInfosForTitles(top10TitleIds) as TitleInformation[]
      if (!Array.isArray(top10TitleInfos)) {
        top10TitleInfos = [top10TitleInfos]
      }

      const endDate = timeRange.getCurrentEndDate()
      const startDate = sub(endDate, { days: 90 })

      for (const titleInfo of top10TitleInfos) {
        try {
          const titleChartData = await fetchTitleChartData(
            titleInfo,
            startDate,
            endDate
          )
          top5TitleChartData.push(titleChartData)
          if (top5ChartData.value.value && top5ChartData.value.value?.length >= 5) {
            break
          }
        } catch (e) {
          console.log('Error fetching quotes')
        }
      }
    }

    top5ChartData.value = {
      loading: false,
      value: top5TitleChartData
    }
  }

  return {
    titles,
    titleFilter,
    messageFilter,
    loadData,
    setFilter,
    entityType,
    loadNextMessages,
    resetMessages,
    treeData,
    topicsList,
    executiveSummary,
    messageStatistics,
    messageHistoryChartData,
    messages,
    entityInformation,
    referencedPeople,
    referencedPeopleInfos,
    top5ChartData,
    keyEventsForTitles,
    indexChartData,
    indexQuoteChartData,
    indexTitleInformation,
    loadIndexChartData
  }
})
