import axios from 'axios'
import * as crypto from 'crypto-js'
import Cookies from 'js-cookie'
import { api } from 'boot/axios'

export const COOKIE_EMAIL = 'email'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Features {
}

export interface UserData {
  username: string
  active: number
  validTime: number
  language: string
  succession: number
  subscription_type: string
  tokenValidUntil: any
  gender: string
  first_name: string
  last_name: string
  bday: any
  street: any
  number: any
  city: any
  zip: any
  country: any
  phone: any
  registration: number
  valid_until: number
  newsletter: number
  nl_json: any
  rebate: any
  rebate_valid_until: any
  abo_months: any
  filter_id: number
  max_api_version: number
  max_saved_stocks: number
  max_watchlists: number
  max_stocks_watchlists: number
  intraday: number
  delay_minutes: number
  history_months: number
  pulse_picks_regions: string[]
  pulse_picks_count: number
  max_dashboard_tabs: number
  max_titles_tab: number
  change_signal_rules: number
  global_signal_rules: number
  active_signal_rules: number
  history_model_type: number
  max_subscriptions: number
  colorized_messages: number
  filter: any[]
  features: Features
  formats: string[]
}

export interface LoginResult {
  success: boolean,
  messageCode: string,
  userData: UserData|null
}

export class LoginCredentials {
  username: string
  password: string

  constructor (username: string, password: string) {
    this.username = username
    this.password = password
  }

  toAuthString (): string {
    return btoa(`${this.username}:${crypto.MD5(this.password)}`)
  }
}

export interface ResetResult {
  success: boolean,
  messageCode: string
}

export interface NewPasswordResult {
  success: boolean,
  messageCode: string
}

class AuthenticationService {
  public async login (credentials: LoginCredentials, rememberMe: boolean): Promise<LoginResult> {
    if (!credentials.username) {
      return { success: false, messageCode: 'AuthenticationService.message.login.usernameRequired', userData: null }
    }
    if (!credentials.password) {
      return { success: false, messageCode: 'AuthenticationService.message.login.passwordRequired', userData: null }
    }

    const authString = credentials.toAuthString()

    try {
      const response = await api.get<UserData>('/userdata', {
        // Add token manually. After success the token will be set globally
        headers: { Authorization: `Basic ${authString}` }
      })

      if (rememberMe) {
        Cookies.set(COOKIE_EMAIL, credentials.username, { expires: 365, path: '/' })
      } else {
        Cookies.remove(COOKIE_EMAIL, { path: '/' })
      }

      return { success: true, messageCode: 'AuthenticationService.message.login.success', userData: response.data }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        return { success: false, messageCode: 'AuthenticationService.message.login.invalidCredentials', userData: null }
      }

      return { success: false, messageCode: 'AuthenticationService.message.login.failure', userData: null }
    }
  }

  public async resetPassword (email: string) : Promise<ResetResult> {
    // from dashboard:
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!email || email.length < 1 || !emailRegex.test(email)) {
      return { success: false, messageCode: 'AuthenticationService.message.reset.invalidEmail' }
    }

    try {
      const response = await api.get(`/userdata/requestpasswordreset/${email}`)
      if (response.status === 200) {
        return { success: true, messageCode: 'AuthenticationService.message.reset.success' }
      }
      return { success: false, messageCode: 'AuthenticationService.message.reset.failure' }
    } catch (error) {
      return { success: false, messageCode: 'AuthenticationService.message.reset.failure' }
    }
  }

  public async setNewPassword (resetToken: string, newPassword: string, newPasswordConfirm: string) : Promise<NewPasswordResult> {
    if (!resetToken || resetToken.length < 1) {
      return { success: false, messageCode: 'AuthenticationService.message.setPassword.invalidToken' }
    }
    if (!newPassword || newPassword.length < 1) {
      return { success: false, messageCode: 'AuthenticationService.message.setPassword.invalidPassword' }
    }
    if (!newPasswordConfirm || newPasswordConfirm.length < 1) {
      return { success: false, messageCode: 'AuthenticationService.message.setPassword.invalidPasswordConfirm' }
    }
    if (newPassword.length < 8) {
      return { success: false, messageCode: 'AuthenticationService.message.setPassword.passwordFormat' }
    }
    if (newPassword !== newPasswordConfirm) {
      return { success: false, messageCode: 'AuthenticationService.message.setPassword.passwordMismatch' }
    }

    try {
      const body = {
        reset_token: resetToken,
        newpassword: `${crypto.MD5(newPassword)}`
      }

      const response = await api.post('/userdata/newpassword', body)
      if (response.status === 200) {
        return { success: true, messageCode: 'AuthenticationService.message.setPassword.success' }
      }

      return { success: false, messageCode: 'AuthenticationService.message.setPassword.failure' }
    } catch (error) {
      return { success: true, messageCode: 'AuthenticationService.message.setPassword.failure' }
    }
  }
}

export default AuthenticationService
