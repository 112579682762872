import { TrackingAction, TrackingContext } from 'boot/plausible'
import { RouteLocationRaw } from 'vue-router'
import { capitalizeFirstLetter } from 'src/helper/StringHelper'
import { EntityEnum } from 'src/helper/EntityHelper'

export abstract class AbstractEntity {
  protected readonly id: number
  protected readonly type: EntityEnum
  protected readonly name: string | undefined
  protected abstract readonly defaultIcon: string

  public constructor (entityId: number, entityType: EntityEnum, name?: string) {
    this.id = entityId
    this.type = entityType
    this.name = name
  }

  public getId (): number {
    return this.id
  }

  public getType (): EntityEnum {
    return this.type
  }

  public getTypeLabel (): string {
    return capitalizeFirstLetter(this.type.replace('_', ' '))
  }

  public getName (): string {
    return this.name || this.getNameFromInventory()
  }

  public getShortName (): string {
    return this.getName()
  }

  protected abstract getNameFromInventory (): string
  public abstract getIcon (): string
  public abstract getSubType(): string
  public abstract getTrackingAction (): TrackingAction
  public abstract getTrackingContext (): TrackingContext
  public abstract getRouteOptions (): RouteLocationRaw
}
